import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { useMediaQuery } from "react-responsive"

// Components
import { Layout } from "../components/Layout"
import SEO from "../components/seo"
import PostEntry from "../components/PostEntry"
import BlogsMobileBanner from "../components/BlogsMobileBanner"

// Custom styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "./blog.module.scss"

const CategoryTemplate = props => {
  const {
    data,
    data: {
      wpcontent: { category },
    },
  } = props

  // react-responsive with hooks
  const isMax575px = useMediaQuery({ maxWidth: 575 })

  return (
    <Layout>
      <SEO
        title={category.name}
        image={data.seoBanner.childImageSharp.resize}
      />
      <Container
        fluid
        className={styles.container}
        css={css`
          background-image: url(${isMax575px
            ? "unset"
            : data.bannerBackgroundImage.publicURL});
        `}
      >
        {isMax575px ? (
          <BlogsMobileBanner
            title="Blogs"
            subTitle={`Category: ${category.name}`}
          />
        ) : (
          <Container className={styles.bannerContainer}>
            <Row className={styles.bannerRow}>
              <Col md={5} className={styles.header}>
                <h1>Blogs</h1>
                <p>Category: {category.name}</p>
              </Col>
              <Col className={styles.imgCol}>
                <Img
                  fluid={data.banner.childImageSharp.fluid}
                  className={styles.bannerImg}
                  alt="AccountablePH Blog"
                />
              </Col>
            </Row>
          </Container>
        )}
      </Container>
      <Container fluid className="pb-5 pt-5">
        <Row>
          <Col className="d-flex justify-content-center">
            <div
              className="card-columns"
              style={{
                maxWidth: "1040px",
                columnGap: "1.5rem",
              }}
            >
              {category.posts.nodes &&
                category.posts.nodes.map(post => (
                  <Fragment key={post.id}>
                    <PostEntry post={post} />
                  </Fragment>
                ))}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default CategoryTemplate

export const pageQuery = graphql`
  query GET_CATEGORIES_AND_GATSBY_IMAGES($id: ID!) {
    wpcontent {
      category(id: $id) {
        id
        name
        slug
        posts(first: 100) {
          nodes {
            ...PostEntryFragment
          }
        }
      }
    }

    seoBanner: file(relativePath: { eq: "banner/seo-blogs.png" }) {
      publicURL
      childImageSharp {
        resize(width: 1024) {
          src
          height
          width
        }
      }
    }

    bannerBackgroundImage: file(relativePath: { eq: "banner/background.svg" }) {
      publicURL
    }

    banner: file(relativePath: { eq: "blog/blogs.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
