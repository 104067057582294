import React from "react"
import Image from "react-bootstrap/Image"
import { useStaticImages } from "../hooks/useStaticImages"
import { css } from "@emotion/core"

const BlogsMobileBanner = ({ title, subTitle }) => {
  const { mobileBlogBannerImage } = useStaticImages()
  return (
    <div
      css={css`
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @media (min-width: 576px) {
          display: none;
        }
      `}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
        css={css`
          display: block;
          position: relative;
          width: calc(100% + 176vw);
          height: 114vw;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        `}
      >
        <path
          fill="#3c7b4f"
          d="M1000,4.3V0H0v4.3C0.9,23.1,126.7,99.2,500,100S1000,22.7,1000,4.3z"
        ></path>
      </svg>
      <h1
        css={css`
          font-family: "Montserrat", sans-serif;
          font-size: 6.5vw;
          text-transform: uppercase;
          color: white;
          text-align: center;
          padding-bottom: 1rem;
          margin-top: calc(-79vw - 1rem);
        `}
      >
        {title}
      </h1>
      <p
        css={css`
          display: inline-block;
          width: 45%;
          position: absolute;
          top: 55vw;
          right: 5%;
          color: white;
          text-align: center;
          line-height: 1.2em;
        `}
      >
        {subTitle}
      </p>
      <Image
        src={mobileBlogBannerImage.publicURL}
        alt="AccountablePH Blog"
        fluid
        css={css`
          margin-top: -2.4rem;
          transform: scale(1.03);
          @media (max-width: 425px) {
            margin-top: -2rem;
          }
          @media (max-width: 375px) {
            margin-top: -1.8rem;
          }
          @media (max-width: 320px) {
            margin-top: -1.6rem;
          }
        `}
      />
    </div>
  )
}
export default BlogsMobileBanner
