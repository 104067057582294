import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

// Custom styles & Bootstrap
import Card from "react-bootstrap/Card"
import styles from "./PostEntry.module.scss"

const PostEntry = ({ post }) => {
  const d = new Date(post.date)
  const mm = d.toLocaleString("default", { month: "short" })
  const dd = d.getDate()
  const yyyy = d.getFullYear()
  return (
    <Card
      className={styles.card}
      style={{ marginBottom: "1.5rem" }}
      as={Link}
      to={post.uri}
    >
      <div className={styles.categories}>
        {post.categories.nodes.map(category => (
          <p key={category.id}>{category.name}</p>
        ))}
      </div>
      <Img
        fluid={post.featuredImage.node.imageFile.childImageSharp.fluid}
        objectFit="cover"
        alt={post.title}
        className={styles.cardImage}
      />
      <Card.Body>
        <Card.Title
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: post.title }}
        />
      </Card.Body>
      <Card.Footer className={styles.footer}>
        {`${mm}. ${dd}, ${yyyy}`} • {`${post.readingTime} min read`}
      </Card.Footer>
    </Card>
  )
}

export default PostEntry

export const query = graphql`
  fragment PostEntryFragment on WPGraphQL_Post {
    id
    uri
    title(format: RENDERED)
    featuredImage {
      node {
        sourceUrl
        imageFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    date
    readingTime
    categories {
      nodes {
        id
        name
      }
    }
  }
`
